
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import TypeEffect from '@/components/TypeEffect.vue';
import Axios from "axios";

export default defineComponent({
  name: "App",
  components: { Header, TypeEffect },
  computed: {
    itens() {
      const o = this.options
      if(!o || !o.length) {
        const y = (this as any).totalItens
        y[1].active=true
        return y
      }

      let temp: any = []
      for (const i of o) {
        const n = (i as any).Name
        const x = this.totalItens.filter((f: any) => f.titulo == n)
        
        if(x.length) {
          temp.push(x[0])
        }
      }

      if([0,1].includes(temp.length)) return temp
      
      temp = temp.sort((i: any) => i.active ? 1 : -1)
      const a = temp.pop()
      const b = temp.pop()
      temp.push(a, b)
      return temp
    }
  },

  data() {
    return {
      message: [], options:[], id:'', token:'', atual: null,
      totalItens: [
        {
          id: "1",active:false,
          titulo: "Open Source Pharma",
          imagem: "/static/npo-bg-purple.png",
          logo: "/static/npo-logo-purple.png",
          opcao1: "Trusted Organization",
          opcao2: "Medical Relief organization",
          opcao3: "Pinheiros - São Paulo",
          texto:
            "Loren ipsum dolor sit amet, consectetur adipiscing elit. Nunc euismod nulla ipsumes,quis tempus leo tincidunt et.",
        },
        {
          id: "2",active:true,
          titulo: "The Covid Relief Foundation",
          imagem: "/static/npo-bg-blue.png",
          logo: "/static/npo-logo-blue.png",
          opcao1: "Trusted Organization",
          opcao2: "Medical Relief organization",
          opcao3: "Bela Vista - São Paulo",
          texto:
            "Loren ipsum dolor sit amet, consectetur adipiscing elit. Nunc euismod nulla ipsumes,quis tempus leo tincidunt et.",
        },
        {
          id: "3",active:false,
          titulo: "Madre Teresa Hospital",
          imagem: "/static/npo-bg-green.png",
          logo: "/static/npo-logo-green.png",
          opcao1: "Trusted Organization",
          opcao2: "Medical Relief organization",
          opcao3: "Heliópolis - São Paulo",
          texto:
            "Loren ipsum dolor sit amet, consectetur adipiscing elit. Nunc euismod nulla ipsumes,quis tempus leo tincidunt et.",
        },
      ],
    };
  },

  mounted() {
    const {id, text, items, token} = this.$route.params;
    this.options = JSON.parse(items as string);
    this.message = [text] as any;
    this.token=token as string
    this.id=id as string
  },

  methods: {
    next() {
      const a = this.totalItens.filter((i: any) => i.active)[0]
      a.active=false

      let i = this.totalItens.indexOf(a)
      if(i == 2) i = 0
      else i++
      
      this.totalItens[i].active=true
    },

    previous() {
      const a = this.totalItens.filter((i: any) => i.active)[0]
      a.active=false

      let i = this.totalItens.indexOf(a)
      if(i == 0) i = 2
      else i--
      
      this.totalItens[i].active=true
    },

    async gotoMaps(item:any){
      const payload = JSON.stringify({
        locale: "en-EN",
        type: "message",
        from: {
          id: "user1"
        },
        text: item.titulo
      })
      
      await Axios.request({
        method: 'post',
        data: payload,
        url: `https://directline.botframework.com/v3/directline/conversations/${this.id}/activities`,
        headers: { 
          'Authorization': 'Bearer ' + this.token,
          'Content-Type': 'application/json'
        }
      })

      const temp = this.options.filter((i:any)=> i.Name == item.titulo)
      this.$router.push({
        params: temp[0],
        name: 'maps'
      })
    }
  },
});
